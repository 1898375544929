@use 'variables';

* {
    font-family: 'Exo 2', sans-serif;
}
.BackTableBlock{
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-main);

    .BackTableBlockText {
        font-size: 30px;
        font-weight: 600;
        color: rgb(0, 0, 0);

        @media (max-width: 768px) {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.TableBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    width: 1024px;

    @media (max-width: 768px) {
        width: 600px;
    }

    @media (max-width: 640px) {
        width: 300px;
    }

    // @media (max-width: 320px) {
    //     width: 300px;
    // }
}
