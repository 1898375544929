
.Background {
    padding-top: 5px;
    margin: 0;
    width: 100%;
    height: 80px;
    background-color: var(--color-main);
    display: flex;
    align-items: center;
    justify-content: center;
}

.BigBlockHeader {
    box-sizing: border-box;
    position: relative;
    width: 1024px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        padding-inline: 15px;
        justify-content: space-between;
        margin: 0;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #9b9b9b;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.HeaderBlockOne {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    isolation: isolate;
    background-color: var(--color-main);

    img {
        height: 80px;
        width: 80px;

        @media (max-width: 768px) {
            margin-bottom: 5px;
        }
    }
}

.Hamburger {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;

        span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: black;
        }
    }
}

.HeaderBlockTwo {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 768px) {
    .HeaderBlockTwo {
        display: none;
    }

    .HeaderBlockTwo__isActive {
        position: absolute;
        top: 99%;
        right: 0;
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
        background-color: var(--color-main);
        border-radius: 0px 0px 20px 20px;
        border: 1px solid #9b9b9b;;
    }
}



