.BackgroundBottom {
    width: 100%;
    height: auto;
    padding-top: 40px;
    padding-bottom: 20px;
    background-color:#525e68;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    font-weight: 400;
}

.InteriorBlockBottom {
    width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column-reverse;
        display: flex;
        align-items: center;
    }
}


.MapAndAddressOfLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;

    iframe{
        border-radius: var(--border-radius-main);
     }

     @media (max-width: 768px) {
        align-items: center;
    }
 }

.InformationOfCenter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    span {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    a {
        padding-top: 5px;
        padding-bottom: 5px;
        text-decoration: none;
        color: rgb(255, 255, 255);

        &:hover {
            color: #008d3f;
        }
    }

    @media (max-width: 768px) {
        align-items: center;
    }


}

 .RegistryOfRight {
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px) {
        align-items: center;
        justify-content: center;
    }
 }

.ContactsGeneral {
    display: flex;
    color: #ffffff;
    width: 33.33%;
    height: 100%;

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}

.Right {
    color: rgb(255, 255, 255);

    p {
        display: inline;
    }

    div {
        display: inline;
    }
}