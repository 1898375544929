.Background {
    height: 200px;
    width: 250px;
    margin: 50px 20px;
    padding: 20px;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: var(--border-radius-main);
    text-align: center;
    box-shadow: -2px 1px 12px 2px #dbdbdbb3;

    @media (max-width: 320px) {
        width: 250px;
        height: 200px;
        margin: 30px 10px;
    }

    // &:hover {
    //     box-shadow: -3px 2px 15px 6px #dbdbdb99;
    //     background-color: #ffffff;
    //     cursor: pointer;
    // }
}

.Card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: 600;
}

.Size_Coment {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
}

.Modal_Content {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 5px;

    .Modal_Content_Price {
        @media (max-width: 768px) {
            margin-top: 10px;
            color: #ff0000;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.Modal_Line {
    width: 900px;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;

    @media (max-width: 768px) {
        width: 80%;
        height: 400px;
    }

}

.Green_Line {
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 0.5px;
        margin-bottom: 7px;
        background-color: #008d3f;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}