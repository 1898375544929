.Modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    pointer-events: none;
    opacity: 0;
}

.Modal__isActive {
    opacity: 1;
    pointer-events: all;

    .Modal_Content {
        transform: scale(1);
    }
}



.Modal_Content {
    padding: 20px;
    border-radius: var(--border-radius-main);
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
}

.Modal_children {
    color: #000000;
}
