.BackgroundTop {
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ecf6fc;
    flex-direction: column;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #9b9b9b;
    }
}

.InteriorBlockTop {
    width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100vw;
    }
}

.InteriorBlockLeftTop {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    button {
        margin-left: 50px;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 30%;
        align-items: center;

        button {
            margin-left: 0;
        }
    }
}


.InteriorBlockRightTop {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        height: 70%;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.InteriorBlockRightTop img {
    height: 600px;
    width: 400px;
    border-radius: var(--border-radius-main);

    @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }
}

.InteriorBlockLeftTextBottomOne {
    font-size: 25px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
    }

    @media (max-width: 640px) {
            width: 80%;
            font-size: 16px;
         }
}

.InteriorBlockLeftTextBottomTwo {
    font-size: 20px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-bottom: 80px;

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}

