.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: var(--border-radius-main);
    color: black;
    background-color: var(--color-main);
    font-size: 15px;
    font-weight: 700;
    transition: all .15s ease-in;
    cursor: pointer;

    &:hover {
        color: #008d3f;
    }
}

.Button__isLink,
.Button__isLink:hover {
    text-decoration: none;
}

.Button__isLarge {
    font-size: 20px;
    font-weight: 300;
    box-shadow: -2px 1px 12px 2px #dbdbdbb3;
    background-color: #ffffff;
    padding: 15px 30px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: -3px 2px 15px 6px #dbdbdb99;
    }
}

.Button__isGreen {
    padding: 15px 30px;
    border: none;
    border-radius: var(--border-radius-main);
    color: #ffffff;
    background-color: #008d3f;
    font-size: 20px;
    font-weight: 300;
    box-shadow: -2px 1px 12px 2px #dbdbdbb3;
    transition: all .15s ease-in;
    cursor: pointer;


    &:hover {
        color: #000000;
        background-color: #ffffff;
        border-color: #008d3f;
    }
}





