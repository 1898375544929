.Background {
    height: auto;
    width: 350px;

    @media (max-width: 640px) {
        width: 300px;
    }

    @media (max-width: 320px) {
        width: 250px;
    }


    .InputBlock {
        height: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        input {
            border-radius: var(--border-radius-main);
            border: 1px solid var(--color-main);
            height: 40px;
            box-sizing: border-box;
            width: 100%;
            box-shadow: -2px 1px 12px 2px #dbdbdbb3;
            padding: 5px 10px;
            outline: none;
        }

        input[type="checkbox"] {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }

        input:is(:hover, :focus) {
            border-color: #008d3f;
            box-shadow: -3px 2px 15px 6px #dbdbdb99;
        }

        label{
            margin-top: 10%;
            display: block;
        }

    }

    .SubmitBlock {
        margin-top: 30px;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .NameOfInputBlock {
        font-size: 20px;
        font-weight: 600;
    }

    .SuccessMessage {
        text-align: center;
        color: #008000;
        font-size: 16px;
        margin: 5px;
        position: fixed;
        background-color: rgb(255, 255, 255);
      }

    .ErrorMessage {
        text-align: center;
        color: #ff0000;
        font-size: 16px;
        margin: 10px;
        position: fixed;
        background-color: rgb(255, 255, 255);
    }
}